.list-wrapper {
    position:relative;
    margin-top:-40px;
  }
  .list-item-wrapper {
    margin-top:10px;
    position:relative;
  }
  .list-bullet {
    float:left;
    margin-right:20px;
    background:#ffffff;
    height:20px;
    width:20px;
    line-height:10px;
    border-radius:100px;
    font-weight:700;
    color:white;
    text-align:center;
    margin-left:12px;
    margin-top:10px;
  }
  .list-item {
    display:table-row;
    vertical-align:middle;
  }
  .list-title {
      font-weight:700;
  }
  .list-text {
      font-weight:400;
  }
  .red-line {
    background:#979797;
    z-index:-1;
    width:1px;
    height:100%;
    position:absolute;
    left:21px;
  }
  .white-line {
    background:#FFF;
    z-index:-1;
    top:-10px;
    width:1px;
    height:100%;
    position:absolute;
    left:0px;
  }
