.custom-shape-divider-bottom-1678740708 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1678740708 svg {
    position: relative;
    display: block;
    width: calc(60% + 1.3px);
    height: 180px;
}

.custom-shape-divider-bottom-1678740708 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-top-1678744511 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1678744511 svg {
    position: relative;
    display: block;
    width: calc(128% + 1.3px);
    height: 65px;
}

.custom-shape-divider-top-1678744511 .shape-fill {
    fill: #FFFFFF;
}

.custom-shape-divider-bottom-1678744624 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1678744624 svg {
    position: relative;
    display: block;
    width: calc(120% + 1.3px);
    height: 150px;
}

.custom-shape-divider-bottom-1678744624 .shape-fill {
    fill: #FFFFFF;
}


.text-background {
    background-image: url('./home_page_image.png'); /* Reference image from the public folder */
    background-size: cover;
    background-position: center;
}
